<template>
  <page-template>
    <div class="nk-split nk-split-page nk-split-md">
      <div class="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white">
        <div class="nk-block nk-block-middle nk-auth-body">
          <div class="brand-logo pb-5" v-if="!$isHybridPlatform">
            <brand-logo></brand-logo>
          </div>
          <div class="nk-block-head">
            <div class="nk-block-head-content">
              <h5 class="nk-block-title">{{ $t('Sign-In')}}</h5>
              <div class="nk-block-des">
                <p>{{$t('login_page.sign_in_message')}}.</p>
              </div>
            </div>
          </div><!-- .nk-block-head -->
          <template v-if="1===1">
            <nk-button type="light" outline class="w-100 text-center justify-content-center" v-on:click="signInWithGoogle">
              <img :src="require('@/assets/images/logos/g.png')" alt="Google" style="width: 24px" class="mr-2"/> {{ $t('Login with Google') }}
            </nk-button>
            <div class="text-center pt-4 pb-3">
              <h6 class="overline-title overline-title-sap"><span>{{$t('OR')}}</span></h6>
            </div>
          </template>
          <template v-else>
            <ion-skeleton-text animated style="width: 100%" class="py-3 rounded"></ion-skeleton-text>
            <div class="py-4">
              <ion-skeleton-text animated style="width: 40%" class="m-auto"></ion-skeleton-text>
            </div>
          </template>
          <div class="form-note-s2 pb-4 text-center"><router-link :to="{name: 'Register'}" :key="'ww'+11">{{ $t('Create an account') }}</router-link></div>
          <form v-on:submit.prevent="doLogin(user_email, user_pwd)" class="pb-4">
            <form-group>
              <div class="form-label-group">
                <label class="form-label" for="default-01">{{ $t('Email')}}</label>
              </div>
              <input type="text" class="form-control form-control-lg" id="default-01" placeholder="Enter your email address" v-model="user_email">
            </form-group>
            <form-group>
              <div class="form-label-group">
                <label class="form-label" for="password">{{ $t('Password') }}</label>
                <router-link :to="{name: 'ForgotPassword'}" class="link link-primary link-sm" tabindex="-1">{{$t('Forgot Code?')}}</router-link>
              </div>
              <div class="form-control-wrap">
                <a tabindex="-1" href="javascript:;" class="form-icon form-icon-right passcode-switch" v-on:click="showPwd=!showPwd">
                  <nio-icon icon="ni ni-eye" v-if="!showPwd"></nio-icon>
                  <nio-icon icon="ni-eye-off" v-else></nio-icon>
                </a>
                <input :type="showPwd ? 'text' : 'password'" class="form-control form-control-lg" id="password" :placeholder="$t('login_page.enter_password')" v-model="user_pwd">
              </div>
            </form-group>
            <form-group>
              <form-submit-button :text="$t('Login')"></form-submit-button>
            </form-group>
          </form><!-- form -->
        </div><!-- .nk-block -->
        <auth-footer></auth-footer>
      </div>
    </div>
  </page-template>
</template>

<script>
import {
  IonSkeletonText,
  loadingController,
  toastController,
  alertController,
  isPlatform,
  onIonViewWillEnter
} from "@ionic/vue"
import { BrandLogo } from "@core/components"
import { FormGroup } from "@core/layouts"
import FormSubmitButton from "@/views/auth/components/FormSubmitButton";
import {useStore} from "vuex";
import { useRouter } from "vue-router"
import {ref, defineComponent, onMounted} from "vue";
import NioIcon from "@core/components/nio-icon/NioIcon";
import axios from "@/libs/axios"
import AuthFooter from "@/views/auth/components/AuthFooter";
import authFunctions from "./index";
import {useI18n} from "vue-i18n";
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth"
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    AuthFooter, NioIcon, FormSubmitButton, BrandLogo, FormGroup, IonSkeletonText},
  setup(){

    const store = useStore()
    const i18n = useI18n()
    const isDesktop = isPlatform('desktop')
    let user_email = ref('')
    let user_pwd   = ref('')
    let showPwd    = ref(false)

    const router = useRouter()

    // Sign in with email & password
    const {doLogin} = authFunctions()

    // Sign in with google
    onMounted(()=>{
      if(!isPlatform('hybrid')){
        GoogleAuth.initialize({
          clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          scopes: ['profile'],
        })
      }
    })
    const signInWithGoogle = async () => {
      const response = await GoogleAuth.signIn();
      if(response.id){
        const loader = await loadingController.create({message: i18n.t('Signing In...')})
        await loader.present()
        // disconnect the google user,
        // so it does not automatically sign in on the next Login page reload.
        await GoogleAuth.signOut()
        let params = {
          id_token: response.authentication.idToken,
          client_id: process.env.VUE_APP_API_CLIENT_ID,
          client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
        }
        axios.post('/api/advisor/sign_with_google', params)
            .then( async resp => {
              if(resp.data.access_token){
                store.commit('auth/AUTH_SUCCESS', resp.data)
                await router.push( resp.data.role && resp.data.role === 'is-admin' ? '/admin/dashboard' : '/')
              }
              else{
                if('NOT_FOUND' === resp.data.message){
                  alertController.create({
                    cssClass: isDesktop ? 'alert-web' : '',
                    header: i18n.t('Account not founds'),
                    message: i18n.t('login_page.account_404'),
                    buttons: [
                      {
                        text: i18n.t('Cancel'),
                        cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
                      },
                      {
                        cssClass: isDesktop ? 'alert-btn-primary' : '',
                        text: i18n.t('Sign up'),
                        handler: () => {
                          router.push({name: 'Register'})
                        }
                      },
                    ]
                  })
                      .then((a)=> a.present())
                }
                else if(!['SILENT'].includes(resp.data.message)){
                  toastController.create({message: resp.data.message, duration:3000, color: 'danger'}).then((a)=> a.present())
                }
              }
            })
            .catch( error => {
              alertController.create({
                header: i18n.t('Error'),
                cssClass: isDesktop ? 'alert-web' : '',
                message: i18n.t('Error')+': '+error.response.statusText,
                buttons: [
                  {
                    text: i18n.t('OK'),
                    cssClass: isDesktop ? 'alert-btn-light' : '',
                  }
                ]
              }).then(( a )=> a.present())
            })
            .then( () => loader.dismiss())
      }
    }
    onIonViewWillEnter(()=>{
      user_email.value = user_pwd.value = ''
    })

    return {
      doLogin,
      showPwd,
      signInWithGoogle,
      user_email,
      user_pwd,
    }
  },
})
</script>
